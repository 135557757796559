import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { StyledAboutKarina } from "../../styledComponents/aboutKarina"
import {
  StyledViewAllEventsButtonWrapper
} from "../../styledComponents/eventArchive"
import Layout from "../../components/layout"
import {
  Section,
  SectionHeaderLedeDiv,
  SectionHeading,
  SectionCorporateLogoList
} from "../../styledComponents/section"
import HeroBanner from "../../components/HeroBanner"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import { HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import { StyledAnchorLink, StyledLinkButton, StyledButtonAsLink, StyledButton } from "../../styledComponents/button"
import Icon from "../../components/icon"
import { StyledInfoModal, StyledModalContent, StyledModalCloseButton } from "../../styledComponents/modal"
import SEO from "../../components/seo"
import CorporateTestimonials from "../../components/Corporate/testimonials"
import CorporatePrograms from "../../components/Corporate/ProgramList"
import { analyticClickEvent } from "../../components/googleAnalytics"
import ContactForm from "../../components/formContact"
import { CoroporateLogoList } from "../../components/Corporate/LogoList"
import { StyledGalleryWrapper } from "../../styledComponents/galleryoverrides"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)


function KarinaMoreModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
    analyticClickEvent("More info button","CTA Button", "Corporate page: Read more about Karina")
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      {/* {data} */}
      <p><StyledButtonAsLink onClick={toggleModal} className="learn-more">Read more</StyledButtonAsLink></p>
      <StyledInfoModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal more-info-modal"
      >
        <StyledModalCloseButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledModalCloseButton>
        <StyledModalContent>
          <p>Karina has taught mindfulness to employees at inspiring charities such as Groundswell, who do groundbreaking work with homeless people in London.</p>
          <p>Karina is delighted to have launched Permissioning and Transformation™ workshops in Europe. Due to the success of this program with nurses, it now counts towards CPD points for nurses and midwives. This set of techniques has been used highly effectively for insomnia and anxiety and Karina taught these techniques to many key workers during the first lockdown.</p>
          <p>Additionally, to support people during the Covid isolation crisis, Karina launched the HI CHI mindfulness phone line to give people without smartphones access to her HI CHI app meditations for free. This is the first mindfulness phone line to be launched in the UK and is being promoted by various charities to include a charity that supports isolated hospital patients.</p>
          <p>Karina is a member of The British Holistic Medical Association and The College of Medicine.</p>  
        </StyledModalContent>
      </StyledInfoModal>
    </>
  )
}

function KarinaCalendarModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
    analyticClickEvent("More info button","CTA Button", "Corporate page: Book an appointment")
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <Section className="corporate-wellness-appointment" id="info">
        <Heading level={2}>
          <h2>Would you like to find out more?</h2>
        </Heading>
        <SectionHeaderLedeDiv><p>Schedule a 15 minute call with Karina now to find out more.</p></SectionHeaderLedeDiv>
        <StyledButton onClick={toggleModal} className="learn-more">Book an appointment</StyledButton>
      </Section>
      
      <StyledInfoModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal more-info-modal"
      >
        <StyledModalCloseButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledModalCloseButton>
        <StyledModalContent>
          <ContactForm></ContactForm>
        </StyledModalContent>
      </StyledInfoModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 100;
`;

const Corporate = ({ data, location }) => {
  const image = {
    imageData: data.aboutKarina.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.aboutKarina.featuredImage?.node?.alt || ``,
  }
  return (
    <>
      <Layout location={location} isHomePage>
        <SEO title="Corporate Wellness" description="Helping your employees reset anxiety, increase focus and vitality. Gain new perspectives and improve wellbeing and performance at the workplace." />
        <HeroBanner
          image="/images/karina-grant-corporate-wellness.jpg"
          backgroundPosition="0 0"
          type="pat"
        >
          <HeroText>
            <HeroHeader>Reset anxiety, increase focus and vitality</HeroHeader>
            <HeroDescription>Gain new perspectives and improve wellbeing and performance at work</HeroDescription>
            <StyledAnchorLink inverse="true" className="learn-more" to="/corporate-wellness/#info">Find out more</StyledAnchorLink>
          </HeroText>
        </HeroBanner>

        <SectionCorporateLogoList background='var(--grey-lightest)'>
          <StyledGalleryWrapper>
            <CoroporateLogoList />
          </StyledGalleryWrapper>
        </SectionCorporateLogoList>

        <Section className="corporate-wellness" id="info">
          <article>
            <h1>Corporate Wellness</h1>
            <p>Karina has been successfully teaching people in corporate settings how to overcome anxiety, calm the mind and increase mental focus since 2007. </p>
            <StaticImage className="universal-photo" src="../../images/karina-at-universal-music-group.jpg" alt="A photo of Karina Grant at Universal Music Group"/>
            <h2>Unique training solutions, wellness talks and one to one sessions</h2>
            <p>I am extremely proud and grateful to have established fantastic and longlasting relationships with so many people and taught wellbeing solutions to many CEO's, medical professionals, key workers, entire companies and people from all walks of life.</p>
            <p>This diversity ranges from speaking at The British Government's Department of Energy and Climate Change to teaching mindfulness to staff at one of the largest homelessness charities in London.</p>
            <p>Speed and ease is of the essence for a fast reset at work. I teach techniques that are quick and easy to learn, drawing on 15 years of successful teaching and private sessions with clients.</p>
            <p>Many of the techniques featured in these unique training sessions address stress levels, burnout and anxiety. </p>
            <p>I have taught these techniques to many professionals, NHS key workers, and in corporate environments. A selection of the most relevant techniques will be taught depending on your bespoke requirements.</p>
            <hr />
            <h2>You will learn how to:</h2>
            <ul>
              <li>Increase wellbeing and positive behaviours in the workplace</li>
              <li>Use accessible and fast techniques</li>
              <li>Quickly calm your mind before potentially stressful situations</li>
              <li>Rebalance after stressful events</li>
              <li>Conflict resolution</li>
              <li>Increase concentration and confidence</li>
            </ul>
          </article>
        </Section>
            
        <CorporatePrograms data={data}/>

        <Section className="corporate-wellness-testimoinals">
          
          <article className="corporate-testimonials">
            <h2>Corporate testimonials</h2>
            <CorporateTestimonials />
          </article>
        </Section>

        <StyledAboutKarina>
        {image && image.imageData && (
          <figure>
            <GatsbyImage image={image.imageData} alt={image.alt} transformoptions={{ layout: "FULL_WIDTH" }} />
          </figure>
        )}
          <div>
            <h2>Karina Grant</h2>
            <p>Karina has spent over a decade teaching mindfulness, wellness and energy healing to both medical and complementary health care professionals. Her workshops are attended by many doctors, nurses, public health advisors, research scientists, CEOs, parents, carers, charities, teachers and a variety of people from other professional backgrounds. </p><p>She has also enjoyed teaching meditation in large corporations and has trained people from The World Health Organisation and other prestigious organisations.</p>
          
            <ModalProvider backgroundComponent={FadingBackground}>
              <KarinaMoreModalButton />
            </ModalProvider>
          </div>
        </StyledAboutKarina>

        <BackgroundColourBlock number='2'>
          <ModalProvider backgroundComponent={FadingBackground}>
              <KarinaCalendarModalButton />
          </ModalProvider>
        </BackgroundColourBlock>

       
      </Layout>
    </>
  )
}

export default Corporate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    permissioning: wpMediaItem(id: {eq: "cG9zdDo3ODIw"}) {
        localFile {
            childImageSharp {
                gatsbyImageData (
                    width: 975
                    quality: 100
                    placeholder: TRACED_SVG
                    breakpoints: [320, 720, 1024, 1600]
                )
            }
        }
    },
    qt: wpMediaItem(id: {eq: "cG9zdDo2MjQy"}) {
        localFile {
            childImageSharp {
                gatsbyImageData (
                    width: 975
                    quality: 100
                    placeholder: TRACED_SVG
                    breakpoints: [320, 720, 1024, 1600]
                )
            }
        }
    },
    kaarmibeing: wpMediaItem(id: {eq: "cG9zdDo2OTU0"}) {
        localFile {
            childImageSharp {
                gatsbyImageData (
                    width: 975
                    quality: 100
                    placeholder: TRACED_SVG
                    breakpoints: [320, 720, 1024, 1600]
                )
            }
        }
    },
    mindfulness: wpMediaItem(id: {eq: "cG9zdDo3Nzgx"}) {
        localFile {
            childImageSharp {
                gatsbyImageData (
                    width: 975
                    quality: 100
                    placeholder: TRACED_SVG
                    breakpoints: [320, 720, 1024, 1600]
                )
            }
        }
    },
    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

  }
`

