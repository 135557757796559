import { StaticImage } from "gatsby-plugin-image"
import React from "react"


const CorporateTestimonials = () => {
    return (
        <>
            <blockquote>
                <img src="../../images/corporate/fhtj_logo.png" alt="From Heartache to Joy logo" />
                <p>Karina's sessions have been the most popular calls this year.</p>
                <cite>Eram Saeed, Host and Founder of Global Tele-summit series FHTJ.</cite>
                <hr style={{"marginTop":"1.6rem", "borderTop":"4px solid var(--light-purple-mid)", "borderLeft":"0", "borderRight":"0"}}/>
                <p>I am always in awe of Karina when I hear her speak. </p><p>The way she presents and interacts with the audience is so flawless. </p><p>I always breathe a sigh of relief when I am interviewing Karina because I know she will be very very well prepared. Not only are her presentations well done, the work she does with live callers is some of the best I've witnessed. </p><p>She gets results fast and makes sure to work with the caller until they experience some kind of result, which is typically within a few minutes. I highly give my recommendation for Karina as both a speaker and a healing practitioner.</p>
                <cite>Gretchen Pritts, Co Host for Bliss up and FHTJ</cite>
            </blockquote>
            <blockquote>
                <img src="../../images/corporate/linac_learning_logo.png" alt="Linac Learning" />
                {/* <StaticImage loading="lazy" src="../../images/corporate/linac_learning_logo.png" alt="Linac Learning" /> */}
                <p>Karina was introduced to me by a supporter of my app (iamme) as I am always looking for ways to help young people understand how they can be proactive in how they take care of their own mental health and wellbeing.</p><p> Karina has a special way of communicating her knowledge around mindfulness and energy healing, that helps it to be really relatable, leaving her audience to take away tools that are highly impactful on their everyday.</p><p> Karina has supported my app and has supported our residential workshops and we can't wait to engage with her again.</p><p>I'd highly recommend Karina.</p>
                <cite>Claire Libby - Linac Learning | iamme</cite>
            </blockquote>
            <blockquote>
                <StaticImage loading="lazy" src="../../images/corporate/bolt-angles.png" alt="Bolt Angles Logo" />
                <img src="../../images/corporate/bolt-angles.png" alt="Bolt Angles Logo" />
                <p>Awesome session and evening. So glad I came. Thanks so much Karina 🙏</p>
                <cite>Steve Bolton - CEO of Bolt Angels - Impact Investor - Philanthropist</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/jaaq-logo.png" alt="Just Ask a Question Logo" />
                <p>Amazing!! Can wait to get you on the JAAQ platform 💥</p>
                <cite>Danny Gray - Founder at WAR PAINT & Founder / CEO at JAAQ</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/genuine-dining.png" alt="Genuine Dining Logo" />
                <p>Karina you absolutely blew our minds. Everyone is still talking about it. I'd love to have you back again and again... and again...</p>
                <cite>Danielle Mitchell - Marketing Director at The Genuine Dining Co.</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/hoodrich.png" alt="Hoodrich Logo" />
                <p>Karina came into the Hoodrich office to teach a Permission to Reset™ Anxiety workshop to our staff last week. Everyone really enjoyed the session, commenting that it was the most calm and relaxed they had felt at work in a long time. </p>
                <p>We were taught the impact of how stress in the workplace can affect us, and how if we create small micro-pauses in our day to interrupt the neuropathways in our brain to instead focus on calmness and peace, we would make lasting change within. So we were given small exercises to complete daily that we have all committed to, with the idea that we can influence our minds and bodies how we deal with stress, and I have to say that the team have found the whole experience insightful, inspirational, calming and empowering.</p>
                <p>Karina was extremely knowledgeable, giving us practical and simple tools to use, while also being a truly calming presence in our office. She is an asset to any performance-based workforce, and I would highly recommend her workshop for any team needing to manage their stress/anxiety levels at work as well as in their personal life.</p>
                <cite>Natalie Tobias - Ecommerce Manager at HOODRICHUK</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/situlive-westfield.png?v1" alt="Situ Live &amp; Wesfield Logo" />
                <p>Karina recently ran her Permission to Reset Anxiety session at SITU LIVE, Westfield London. There was great interest in her event, and it was really well-attended with absolutely glowing feedback from all attendees. </p> <p>Her talk was engaging and provided practical wellness techniques that focused on anxiety management. </p> <p>We can’t wait to have her back in the venue for her Quantum-Touch Energy Healing session in September!.</p>
                <cite>Lexie Taylor - Growth Marketing Manager at Situ Live</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/monica-vinader.jpg" alt="Monica Vinader Logo" />
                <p>Supporting our teams for Peak 2021 has never been more important. This included the normal staffing up and recruitment of extra team members, ad hoc training and stocking up of resources to deliver! </p>

                <p>The most important part though, was Karina's training delivered in two lunch &amp; learn sessions last week, giving some really practical ways to de-stress in the moment, re-set and re-charge and give our people the tools they need to look after themselves and their mental health as well as our customers. Thank you Karina. Thoroughly recommend as practical and positive in approach with access to follow up mindfulness sessions to support ongoing practice after the initial sessions.</p>
                <cite>Natalie Trumper - Head of HR at Monica Vinader Ltd</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/tailify.png" alt="Tailify Logo" />
                <p>Karina is a masterful energy healer whose work has been a great blessing to my company and to me personally. Her 1 hour workshop at Tailify lit a fire of possibility, and now a good portion of the company will receive formal training in how to work with energy both for well being and for the productivity of our business. She is a pleasure and a treasure.</p>
                <cite>Ian Randolph - Head of Product and R&amp;D at Tailify - Influencer marketing, powered by science</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/societe-generale.jpg" alt="Societe Generale Logo" />
                <p>As Head of Internal Communications for an investment bank part of my role is to bring new initiatives and services to staff. I started an initiative called health week based on the concept of healthy body, healthy mind to raise awareness about general health and well being.</p>

                <p>Following a recommendation, I invited Karina to Societe Generale to support our staff during Health Week. She led a few classes with employees teaching them about meditation.</p>

                <p>This included some easy to learn techniques which they could use at work to achieve more balance during stressful moments.</p>

                <p>These classes were very well received and the feedback from staff was excellent.</p>

                <p>Following the success of these lunchtime classes, Karina was invited back to give one to one sessions to employees. Given that the word "healing" is rarely used in a bank setting I was pleasantly surprised at the level of repeated uptake and bookings for these sessions.</p>

                <p>Karina treated people with all manner of aches, pains and stress. This ranged from relaxing stiff shoulders, alleviating people's injuries from marathon training and various other issues that employees wanted help with.</p>

                <p>It was a very positive experience for us all. Karina is highly professional and clearly talented at what she does and I would definitely recommend her.</p>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/groundswell.png" alt="Groundswell Logo" />
                <p>Karina has done a range of work with Groundswell to help us make the organisation a ‘healthier place to work’ this has all very well received and has made big impact on the organisation.</p>

                <p>Karina took time to get to know Groundswell before delivering her work and tailored her approach specifically to us. She first ran a session with the whole staff team to help us look at the our ideas of being healthy and how looking after our own health enables us to better support others. This led some clear actions and changes in the work place around issues such as nutrition and eating, drinking water, time management and stress management.</p>

                <p>Next Karina delivered a three session set of training on Mindfulness at Work. Again bespoke training was developed. This gave individuals involved specific tools to deal with stress at work (and outside of work) and has contributed to changing the organisational culture to a healthier way of working.</p>

                <p>Additionally Karina provided one-to-one support to one member of staff, which had a very positive impact in dealing with stress.</p>

                <p>I would strongly recommend Karina - she had a really beneficial influence on Groundswell to help us not only deliver work that improves the health of our clients, but also ensures that our staff and volunteers improve their health.</p>
                <cite>Athol Hallé - Chief Executive Groundswell</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/partnership-counsel.png" alt="Partnership Counsel Logo" />
                <p>Karina is a wonderful healer and practitioner. If you have the chance to have a session with her, I highly recommend it. The energy is palpable, relaxing and amazing! She is also the best teacher I have ever come across in my many years of meditation and energy healing.</p>
                <cite>Tyroon Win - Practice Manager at Partnership Counsel</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/energize-learning.png" alt="Energize Learning Logo" />
                <p>Never has there been and more needy time for de-stressing and relaxing.</p>
                <p>Karina brings a combination of incisive communication and guidance in both group work as well as one-to-one coaching.</p>
                <p>Her passion for Quantum-Touch and her desire to enable others to benefit from it shines through at all times and I have no doubt with time she will become in great demand.</p>
                <p>The energy created in our recent workshop was off the scale and I would highly recommend attending any of her workshops either corporately or personally. Keep up the amazing work Karina!</p>
                <cite>Steve harris - Founder of Energize Learning</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/british-standards-institute.jpg" alt="British Standards Institute Logo" />
                <p>I have been taught by Karina on multiple courses. What can I say - a world class Energy Healer and teacher (she really is that good). Currently as we all know, there is a huge level of fear, anxiety, and stress around us and within us, and many people including our younger ones are suffering more than ever with their mental health. Karina teaches a number of wellness courses and I can think of no one else better qualified. Karina, thank you for all you have taught me and the healing you have given to me and my family over the years. </p>
                <cite>Andrew Bratchell - Enterprise Network Architect at British Standards Institute</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/tailify.png" alt="Tailify Logo" />
                <p> Growing from within, is the foundation for our values and culture at Tailify. Karina played a huge role in helping our team to understand how they can work with their own energy and grow by focusing on their inner space. She took us through the Quantum-Touch Level 1 practitioner training and it was incredibly appreciated by our team. Karina did an excellent job in introducing energy healing within a corporate setting. I am confident that the future of work would be a much more beautiful space if we all took responsibility for the energy we're putting out there. Thank you Karina for teaching us to take care of each other on such a deep level. </p>
                <cite>Claudia Ceke - Head of People &amp; Culture at Tailify</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/trust-for-developing-comunities.png" alt="The Trust For Developing Communities Logo" />
                <p>TDC deliver community work and youth work in Brighton &amp; Hove. When lockdown kicked in, our staff were desperate to work harder than ever to meet growing need - but this was so challenging in the environment, with staff isolated and the office closed. People were under real pressure. We made Karina's mindfulness app available to all staff and Karina spoke to us all by Zoom to show us how it worked. People used it in different ways to bring some calm and some perspective and this really helped us get through. Enormous thanks to Karina for this really valuable support.</p>
                <cite>Athol Hall - Chief Executive at The Trust For Developing Communities</cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/nhs.jpg" alt="NHS - National Health Services Logo" />
                <p>I'm a nurse who works in operating theatres. I started using these techniques to help deal with a stressful personal situation and noticed a positive difference right away... so did my manager at work! She was so impressed that she approved the program to be included toward my Nursing and Midwifery training. I honestly don't know what I would have done without this program. It's powerful!</p><cite><em>Yasmin Z., National Health Service Nurse, London UK</em></cite>
            </blockquote>

            <blockquote>
                <img src="/images/corporate/st-johns-school-logo.png" alt="St Johns School Radlett Logo" />
                <p>Thank you so much for the Mindfulness sessions you delivered to my staff over the past couple of weeks.  Many staff have thanked me for the investment into their wellbeing, as we have been so focussed on our pupils wellbeing.  The opportunity to learn about mindfulness and take part in meditations with you, and using your app, have been excellent.  Your presence (even virtually) is so calming and soothing during such a turbulent time in life.  Thank you so much and we hope to work with you again soon. </p>

                <cite>Alice Aharon - Executive Headteacher - St John's Infant and Nursery School and Fair Field Junior School</cite>

            </blockquote>





        </>
    )
}

export default CorporateTestimonials