import React, { useState } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import styled from "styled-components"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { ProgramSection, ProgramLectureHeader, ProgramLectureList, ProgramLectureListItem, ProgramLectureLede } from "../../styledComponents/corporatePrograms"
import { StyledButton, StyledCloseModalButton } from "../../styledComponents/button"
import Icon from "../../components/icon"
import { StyledModal, StyledInfoModal, StyledModalContent, StyledModalCloseButton } from "../../styledComponents/modal"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import CorporateProgramContent from "../../fixtures/corporate-programs"
import { analyticClickEvent } from "../../components/googleAnalytics"

function FancyModalButton(data) {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const analyticsLabel = 'Corporate page: More info about ' + data.data.title

    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
      analyticClickEvent("More info button","CTA Button", analyticsLabel, "")
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }


    return (
      <>
        <StyledButton onClick={toggleModal} className="learn-more">More information</StyledButton>
        <StyledInfoModal
          isOpen={isOpen}
          afterOpen={afterOpen}
          beforeClose={beforeClose}
          onBackgroundClick={toggleModal}
          onEscapeKeydown={toggleModal}
          opacity={opacity}
          backgroundProps={{ opacity }}
          className=""
        >
         <StyledModalCloseButton onClick={toggleModal}>
            <Icon name="cross" />
          </StyledModalCloseButton>
          <StyledModalContent>
            <h2>{data.data.title}</h2>
          {parse(data.data.content)}
          </StyledModalContent>
        </StyledInfoModal>
      </>
    )
  }
  
  const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
    z-index: 100;
  `;
  
const CorporatePrograms = ({data}) => {

    return (
        <>
        <BackgroundColourBlock number='2'>
            <ProgramSection className="corporate-programs">
                <ProgramLectureHeader>Unique training programs tailored to your specific needs</ProgramLectureHeader>
                <ProgramLectureLede>Companies often choose to run a series of sessions offering employees the opportunity to learn skills from the training programs listed below. These can be taught as a “Lunch &amp; Learn” session and also as a longer workshop.</ProgramLectureLede>
                <ProgramLectureList>
                    <ProgramLectureListItem>
                        <StaticImage src="../../images/programs/permissioning.jpg" alt="" />
                        <h3>{CorporateProgramContent.permissioning.title}</h3>
                        <p>{CorporateProgramContent.permissioning.excerpt}</p>
                        <ModalProvider backgroundComponent={FadingBackground}>
                            <FancyModalButton data={CorporateProgramContent.permissioning}/>
                        </ModalProvider>
                    </ProgramLectureListItem>
                    <ProgramLectureListItem>
                        <StaticImage src="../../images/programs/quantum-touch.jpg" alt="" />
                        <h3>{CorporateProgramContent.qt.title}</h3>
                        <p>{CorporateProgramContent.qt.excerpt}</p>
                        <ModalProvider backgroundComponent={FadingBackground}>
                            <FancyModalButton data={CorporateProgramContent.qt}/>
                        </ModalProvider>
                    </ProgramLectureListItem>
                    <ProgramLectureListItem>
                        <StaticImage src="../../images/programs/kaarmibeing.jpg" alt="" />
                        <h3>{CorporateProgramContent.kaarmibeing.title}</h3>
                        <p>{CorporateProgramContent.kaarmibeing.excerpt}</p>
                        <ModalProvider backgroundComponent={FadingBackground}>
                            <FancyModalButton data={CorporateProgramContent.kaarmibeing}/>
                        </ModalProvider>
                    </ProgramLectureListItem>
                    <ProgramLectureListItem>
                        <StaticImage src="../../images/programs/permission-reset-anxiety.jpg" alt="" /> 
                        <h3>{CorporateProgramContent.mindfulness.title}</h3>
                        <p>{CorporateProgramContent.mindfulness.excerpt}</p>
                        <ModalProvider backgroundComponent={FadingBackground}>
                            <FancyModalButton data={CorporateProgramContent.mindfulness}/>
                        </ModalProvider>
                    </ProgramLectureListItem>
                </ProgramLectureList>
            </ProgramSection>
        </BackgroundColourBlock>
    </>
    )
}

export default CorporatePrograms

