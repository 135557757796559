import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { Section } from "./section"
import { bold18, regular14 } from "./typography"

const ProgramSection = styled(Section)`
    
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
        /* margin-bottom: 4rem; */
    }
/* 
    > p {
        grid-column: span 4;
    } */
    

`

const ProgramOverview = styled.div`
    grid-column: span 4;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 1 / 7;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        /* margin-bottom: 4rem; */
    }

    h2 {
        @media screen and (min-width: ${breakpoints.md}px) {
            text-align: center;
        }
    }
    p{
        grid-column: span 4;
        text-align: left;
        margin-top: 1.6rem;
    }

    ul{
        list-style-type:none;
        padding-left: 2.4rem;
        li {
            padding-left: 0;
            padding-right: 0;
            position:relative;
            padding-bottom: 1.4rem;

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        li::before {
            content: ' ';
            position: absolute;
            top: 0.2rem;
            left: -2rem;
            display: inline-block;
            transform: rotate(38deg);
            height: 1.6rem;
            width: 1rem;
            border-bottom: 4px solid var(--green-strong);
            border-right: 4px solid var(--green-strong);
        }
    }
`

const ProgramLectureHeader = styled.h2`
    grid-column: span 4;
    text-align: left;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
        text-align: center;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        margin-bottom: 2.4rem;
    }
`

const ProgramLectureLede = styled.p`
    grid-column: span 4;
    text-align: left;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 1 / 7;
        text-align: center;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        margin-bottom: 2.4rem;
    }
`

const ProgramLectureList = styled.ul`
    ${baseGridStyles}
    grid-column: span 4;
    list-style-type: none;
    padding: 0;
    margin: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0;
    }

`

const ProgramLectureListItem = styled.li`
    grid-column: span 4;
    border: 1px solid var(--grey-light);
    border-radius: 3px;
    padding: 0;
    text-align: center;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 3;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        /* grid-column: span 3; */
        
    }

    h3 {
        ${bold18}
        text-align: left;
        margin: 0 1.6rem 0 1.6rem;
    }
    p {
        ${regular14}
        text-align: left;
        margin: 0 1.6rem 0 1.6rem;
    }
    button.learn-more {
        margin: 1.6rem auto 1.6rem auto;
    }
 
`


const ProgramBenefits = styled.div`
    grid-column: span 4;
    order: 3;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 1 / 7;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        /* margin-bottom: 4rem; */
    }

    h2 {
        @media screen and (min-width: ${breakpoints.md}px) {
            text-align: center;
        }
    }
    p{
        grid-column: span 4;
        text-align: left;
        margin-top: 1.6rem;
    }

    ul {
        @media screen and (min-width: ${breakpoints.md}px) {
            margin-left: 2.4rem;
        }
        li {
            margin-bottom: 1rem;
        }
    }

    ul{
        list-style-type:none;
        padding-left: 2.4rem;
        li {
            padding-left: 0;
            padding-right: 0;
            position:relative;
            padding-bottom: 1.4rem;

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        li::before {
            content: ' ';
            position: absolute;
            top: 0.2rem;
            left: -2rem;
            display: inline-block;
            transform: rotate(38deg);
            height: 1.6rem;
            width: 1rem;
            border-bottom: 4px solid var(--green-strong);
            border-right: 4px solid var(--green-strong);
        }
    }
`
export {
    ProgramSection,
    ProgramOverview,
    ProgramBenefits,
    ProgramLectureHeader,
    ProgramLectureLede,
    ProgramLectureList,
    ProgramLectureListItem
}